/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Para leer y escribir en una partición NTFS se tiene que utilizar el driver ntfs-3g. Lo primero que hacemos es instalarlo:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\"><span class=\"token function\">apt-get</span> <span class=\"token function\">install</span> ntfs-3g</code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Buscamos cual es el nombre de nuestra partición ntfs:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\"><span class=\"token function\">fdisk</span></code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Una vez encontrado el nombre del dispositivo, esto es fácil porque pondrá NTFS, montamos."), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\"><span class=\"token function\">mount</span> <span class=\"token parameter variable\">-t</span> ntfs-3g /dev/hda1 /media/windows</code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Y comprobamos que podemos entrar:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"text\"><pre class=\"language-text\"><code class=\"language-text\">cd /media/windows\nls</code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Reconfiguramos los locales para no tener problemas con los caracteres especiales de nuestro idioma."), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\">dpkg-reconfigure locales</code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Después abrimos con nuestro editor preferido el archivo /etc/fstab , aquí es donde los cambios los haremos definitivos. Añadimos en el archivo una línea como ésta:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"text\"><pre class=\"language-text\"><code class=\"language-text\">/dev/hda1 /media/windows ntfs-3g silent,users,nls=es\\_ES 0 0</code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Donde yo he puesto /dev/hda1 vosotros tendréis que poner vuestro nombre de dispositivo. Y con esto ya tendréis la partición siempre montada. Un saludo"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
